import detectBrowser from "./detect-browser";

export const data = [
  {
    title: "Credits",
    description: `This project developed by Eray Ersan, a student at Istanbul University Faculty of Law.  
I would like to thank Elif Sayar, a student at Istanbul University Faculty of Law, for her contributions to Cookie Research in this project.`,
    extraFunction: (addLog) => {
      console.log("");
      addLog(
        `Project Owner: <a className="credit" target="_blank" href = "https://www.linkedin.com/in/eray-ersan-840048223/">Eray Ersan </a>`
      );
      addLog(
        `Cookie Researcher: <a className="credit" target="_blank" href = "https://www.linkedin.com/in/elif-sayar-a972232bb?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app ">Elif Sayar </a>`
      );
    },

    cookieType: "ads",
    color: "#000",
  },
  {
    title: "Google Analytics (_ga)",
    description: `
      <h3>_ga Cookie</h3>
    <ul>
        <li><strong>Purpose:</strong> To identify and track website users using Google Analytics.</li>
        <li><strong>Duration:</strong> 2 years, it is a persistent cookie.</li>
        <li><strong>Data Collected:</strong> Unique user ID (Client ID), timestamps, session information, and traffic sources.</li>
    </ul>

    <h2>How It Works:</h2>
    <ul>
        <li><strong>User Identification:</strong> A random unique ID is generated during the first visit, which is stored in the _ga cookie.</li>
        <li><strong>Activity Tracking:</strong> It collects information on how long users spend on pages and which pages they visit.</li>
        <li><strong>Traffic Source:</strong> It identifies how users arrived at the website (e.g., through search engines or social media).</li>
    </ul>

    <h2>Importance:</h2>
    <ul>
        <li>Helps analyze user behavior for website optimization.</li>
        <li>Tracks the effectiveness of marketing campaigns and provides information on conversion rates.</li>
    </ul>

    <h2>Privacy Compliance:</h2>
    <p>Since it collects personal data, it requires compliance with privacy laws like GDPR and KVKK. Explicit consent from users is necessary.</p>

    <h2>Summary:</h2>
    <p>In summary, the _ga cookie is a critical tool for tracking user activity and should be used with attention to user privacy.</p>
      `,
    extraFunction: (addLog) => {
      const cookieContainer = document.querySelectorAll(".product-item");
      const visitorLook = [];

      const buttonClickHandlers = [];
      const mouseEnterHandlers = [];

      // Dinleyicileri ekle
      cookieContainer.forEach((cookie) => {
        if (cookie) {
          const product_title =
            cookie.getElementsByClassName("product-name")[0];
          const button = cookie.querySelector(".add-to-cart");

          // Buton için dinleyici
          const buttonClickHandler = () => {
            if (product_title) {
              const productName = product_title.textContent;
              addLog(
                `The user clicked to purchase this product: ${productName} The user may also be interested in other products related to this one. Change the ad preferences.`
              );
            }
          };
          button.addEventListener("click", buttonClickHandler);
          buttonClickHandlers.push({ button, handler: buttonClickHandler });

          // Mouseenter için dinleyici
          const mouseEnterHandler = (e) => {
            addLog(`User looking the ${product_title.textContent}`);
            visitorLook.push(product_title.textContent);
            addLog(
              `User checked the ${product_title.textContent} for ${
                visitorLook.filter((item) => item === product_title.textContent)
                  .length
              }`
            );
          };
          cookie.addEventListener("mouseenter", mouseEnterHandler);
          mouseEnterHandlers.push({ cookie, handler: mouseEnterHandler });
        }
      });

      // Tüm dinleyicileri kaldırmak için fonksiyon
      const removeAllEventListeners = () => {
        // Buton tıklama dinleyicilerini kaldır
        buttonClickHandlers.forEach(({ button, handler }) => {
          button.removeEventListener("click", handler);
        });

        // Mouseenter dinleyicilerini kaldır
        mouseEnterHandlers.forEach(({ cookie, handler }) => {
          cookie.removeEventListener("mouseenter", handler);
        });

        // Dinleyici referanslarını temizle
        buttonClickHandlers.length = 0;
        mouseEnterHandlers.length = 0;
      };

      function generateClientId() {
        return "GA1." + Math.floor(Math.random() * 10000) + "." + Date.now(); // Benzersiz Client ID üret
      }

      // Oturum (Session) bilgilerini üretme
      function generateSessionId() {
        return "Session-" + Math.floor(Math.random() * 100000); // Rastgele oturum kimliği oluştur
      }

      // Tarayıcı ve cihaz bilgilerini simüle etme

      // Kullanıcının ziyaret ettiği sayfa URL'sini simüle etme
      function getPageUrl() {
        return window.location.href; // Kullanıcının ziyaret ettiği sayfa URL'si
      }

      // IP adresinden elde edilen konum bilgisini simüle etme (örneğin, şehir bazında)
      function getLocationInfo() {
        return "Simulated City, Simulated Country"; // Gerçek veri toplama yapılmadığından simüle edilen konum
      }

      // Çerezin ne kadar süreyle geçerli olacağını hesaplayan fonksiyon (2 yıl)
      function cookieExpirationTime() {
        const expirationYears = 2;
        let expirationDate = new Date();
        expirationDate.setFullYear(
          expirationDate.getFullYear() + expirationYears
        ); // 2 yıl ekle
        return expirationDate.toUTCString();
      }
      const clientId = generateClientId();
      const sessionId = generateSessionId();
      const expiration = cookieExpirationTime();
      // _ga çerezi simülasyonu, her 2 saniyede bir çalışacak
      function simulateGA() {
        const browserInfo = detectBrowser(); // Tarayıcı ve cihaz bilgilerini al
        const pageUrl = getPageUrl(); // Sayfa URL'sini al
        const locationInfo = getLocationInfo(); // Konum bilgisini al // Çerezin ne kadar süre geçerli olduğunu hesapla
        // Simülasyon çıktısını konsola yazdır
        addLog(`
        User client id: ${clientId} <br>
        User session id: ${sessionId} <br>
        User using this browser ${browserInfo} <br>
        ${pageUrl} <br>
        ${locationInfo} <br>
        This cookie will stay in the system for 2 years ${expiration} <br>
        `);
      }

      simulateGA();

      return () => {
        visitorLook.length = 0;
        removeAllEventListeners();
      };
      // Durdurma fonksiyonu
    },
    cookieType: "ads",
    color: "#2980b9",
  },
  {
    title: "(mouse_tracking_cookie)",
    description: `<h1>What Are Mouse Tracker Cookies?</h1>
    <p>
        Mouse tracker cookies are a type of tracking technology used primarily for behavioral analytics and user experience (UX) optimization. They monitor and record users' mouse movements, clicks, scrolls, and hover patterns while navigating a website. These cookies, often part of larger tracking systems, collect data to better understand user behavior on a page, including which elements users interact with the most, how long they stay on a particular section, and how they navigate through the website.
    </p>

    <h2>Key Functions and Uses of Mouse Tracker Cookies:</h2>

    <ul>
        <li>
            <strong>Behavioral Analytics:</strong>
            Mouse tracker cookies help businesses analyze how users interact with their websites. By collecting data on clicks, movements, and scrolls, companies can identify trends in user behavior, such as which parts of the website are most engaging or where users tend to click the most.
        </li>
        <li>
            <strong>Heatmaps:</strong>
            These cookies often feed data into tools that generate heatmaps, which visually represent where users click, move, or hover their mouse the most on a webpage. This is useful for optimizing the layout by identifying high-traffic areas or dead zones.
        </li>
        <li>
            <strong>UX and UI Improvements:</strong>
            The data gathered through mouse tracking helps UX/UI designers refine web interfaces. For example, if users struggle to find a particular button or menu, it can highlight usability issues, prompting design adjustments to improve the user journey.
        </li>
        <li>
            <strong>Conversion Rate Optimization (CRO):</strong>
            Businesses can use the data from mouse tracker cookies to identify potential obstacles that prevent users from completing actions like filling out forms or making purchases. By understanding where users drop off or hesitate, companies can tweak design elements to increase conversion rates.
        </li>
    </ul>`,
    extraFunction: (addLog) => {
      const mouseMoveHandler = (event) => {
        const x = event.clientX;
        const y = event.clientY;

        const logMessage = `Your mouse position infos sending to related site's servers. Mouse Position: X: ${x}, Y: ${y}`;

        console.log(logMessage);
        addLog(logMessage); // Log ekle
      };

      // Sağ tıklama için dinleyici
      const rightClickHandler = (event) => {
        event.preventDefault(); // Varsayılan sağ tıklama menüsünü engelle
        addLog("Right click detected!");
      };

      // Tıklama için dinleyici
      const clickHandler = (event) => {
        const clickMessage =
          "Your mouse click info sending to releated site's servers. Mouse Clicked";

        addLog(clickMessage);
      };

      // Mouse down için dinleyici
      const mouseDownHandler = (event) => {
        switch (event.button) {
          case 0:
            addLog("Left click detected!");
            break;
          case 1:
            addLog("Middle button click detected!");
            break;
          case 2:
            rightClickHandler(event); // Sağ tıklama dinleyici
            break;
          default:
            console.log("Unknown button click detected!");
        }
      };

      // Durdurma fonksiyonu
      const removeAllEventListeners = () => {
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("click", clickHandler);
        document.removeEventListener("mousedown", mouseDownHandler);
      };

      // Dinleyicileri ekle
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("click", clickHandler);
      document.addEventListener("mousedown", mouseDownHandler); // Bu satırı değişiklik yapmadan bırakıyoruz

      // Bu fonksiyonu çağırarak dinleyicileri kaldır
      return () => {
        removeAllEventListeners(); // Dinleyicileri kaldır
      };
    },
    cookieType: "ads",
    color: "#CD5C5C",
  },
  {
    title: "Hotjar (_hjIncludedInSample)",
    description: ` <h2>Key Functions and Uses of Heatmap Cookies:</h2>

    <ul>
        <li>
            <strong>Visualizing User Behavior:</strong>
            Heatmap cookies help businesses visually understand how users interact with their web pages. By representing the most clicked or hovered-over areas with warmer colors (like red or yellow), these cookies allow designers and marketers to identify which sections of the page draw the most attention.
        </li>
        <li>
            <strong>UX and Design Optimization:</strong>
            The insights gathered from heatmap cookies enable UX designers to optimize the layout and content placement. For example, if users are frequently clicking on non-clickable areas or ignoring important buttons, designers can adjust the design to make navigation more intuitive.
        </li>
        <li>
            <strong>Improving Call-to-Actions (CTAs):</strong>
            Heatmaps can highlight whether users are noticing and engaging with key call-to-actions, like "Buy Now" or "Subscribe" buttons. If these areas show low activity, the business can reposition or redesign them for better visibility.
        </li>
        <li>
            <strong>Mobile vs. Desktop Comparison:</strong>
            Heatmap cookies can also be used to compare user behavior on different devices, such as mobile and desktop. This helps businesses ensure that their website is equally effective across all platforms.
        </li>
        <li>
            <strong>Conversion Rate Optimization (CRO):</strong>
            By identifying potential bottlenecks or confusing design elements through heatmaps, businesses can improve conversion rates. This involves making adjustments based on where users hesitate, drop off, or ignore certain elements of the site.
        </li>
    </ul>`,
    extraFunction: (addLog) => {
      let circles = [];

      function handleMouseMove(event) {
        const existingCircle = circles.find((circle) => {
          const rect = circle.getBoundingClientRect();
          return (
            event.pageX >= rect.left &&
            event.pageX <= rect.right &&
            event.pageY >= rect.top &&
            event.pageY <= rect.bottom
          );
        });

        if (existingCircle) {
          // Eğer mevcut bir daire varsa, rengini bir üst tona geçir
          if (existingCircle.classList.contains("green")) {
            existingCircle.classList.remove("green");
            existingCircle.classList.add("yellow");
          } else if (existingCircle.classList.contains("yellow")) {
            existingCircle.classList.remove("yellow");
            existingCircle.classList.add("orange");
          } else if (existingCircle.classList.contains("orange")) {
            existingCircle.classList.remove("orange");
            existingCircle.classList.add("red");
          }
        } else {
          // Eğer mevcut bir daire yoksa, yeni bir yeşil daire oluştur
          const circle = document.createElement("div");
          circle.classList.add("circle", "green");

          // Dairenin konumunu ayarla
          circle.style.left = `${event.pageX - 7.5}px`; // Dairenin merkezini tıklanan yere getirmek için -7.5 (yarıçap)
          circle.style.top = `${event.pageY - 7.5}px`;

          // Daireyi sayfaya ekle ve circles dizisine ekle
          document.body.appendChild(circle);
          circles.push(circle);
        }
      }

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        const circles = document.querySelectorAll(".circle");
        document.removeEventListener("mousemove", handleMouseMove);
        // Her bir elemanı döngüyle geç ve kaldır
        circles.forEach((circle) => {
          circle.remove();
        });
      };
    },
    cookieType: "zombie",
    color: "#45a049",
  },

  // Diğer cookie tanımları...
];
