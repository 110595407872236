import parse from "html-react-parser";

const CookieDescriptionPanel = ({ description, onClose }) => {
  return (
    <div className="model-container">
      <div className="model-content">
        <h2>Cookie Description</h2>
        <p>{parse(description)}</p> {/* HTML parse işlemi yapılabilir */}
        <button onClick={onClose}>OK</button>{" "}
        {/* "Ok" butonu paneli kapatacak */}
      </div>
    </div>
  );
};

export default CookieDescriptionPanel;
