import React, { useState } from "react";
import "./App.css"; // Tarzınıza göre CSS dosyası ekleyebilirsiniz

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  const closeWebsite = () => {
    return window.close();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        We use only essential cookies to ensure the basic functionality of our
        website. These cookies cannot be turned off as they are required for
        site operation.
      </p>
      <div className="banner-buttons">
        <button onClick={handleClose} className="cookie-banner__button">
          Accept
        </button>
        <button onClick={closeWebsite} className="cookie-banner__button">
          Close the Website
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
