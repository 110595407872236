import React, { useState } from "react";

function EntryPanel() {
  const [visible, setVisible] = useState(true);

  function handleVisible() {
    setVisible(false);
  }

  if (!visible) {
    return null;
  }

  return (
    <div className="entry-panel">
      <h1>Hello World</h1>
      <p className="entry-text">
        This website is an awareness project that simulates how non-essential
        cookies track you while navigating the site. When you visit, you'll have
        the opportunity to see step by step how the non-essential cookies you've
        accepted are following your activities. In our project, no trackers
        other than non-essential cookies have been used. You can start by
        clicking on the cookie you're curious about. In the panel at the bottom
        right, you can examine which data the relevant cookie is sending.
      </p>
      <p>Eray Ersan</p>
      <button onClick={handleVisible}>OK</button>
    </div>
  );
}

export default EntryPanel;
