import "./App.css";
import CookieContainer from "./components/CookieContainer";
import { data } from "./data";
import LogPanel from "./components/LogPanel";
import React, { useState } from "react";
import EntryPanel from "./components/EntryPanel";
import parse from "html-react-parser";
import Page from "./components/Page";
import CookieDescriptionPanel from "./components/CookieDescriptionPanel";

const App = () => {
  const [activeFunction, setActiveFunction] = useState(null);
  const [showPanel, setShowPanel] = useState("null");
  const [showPanelVisible, setShowPanelVisible] = useState(false);
  const [logs, setLogs] = useState([]);

  const handleClick = (cookie) => {
    // Eğer bir fonksiyon zaten aktifse durdur
    if (activeFunction) {
      activeFunction(); // Önceki fonksiyonu durdur
    }

    // Yeni fonksiyonu başlat
    const stopFunction = cookie.extraFunction(addLog);
    setActiveFunction(() => stopFunction); // Fonksiyonu setActiveFunction içinde fonksiyon olarak ayarla
    setShowPanel(cookie.description);
    setShowPanelVisible(true);
    // Log ekleme
    addLog(parse(`Log ${logs.length + 1}: ${cookie.title} clicked`));
  };

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const handleClosePanel = () => {
    setShowPanelVisible(false); // Paneli kapat
  };

  return (
    <div className="Page">
      {/* <h1 className="title">How Are You Being Tracked?</h1> */}
      <div className="mainContainer">
        {data.map((cookie, index) => (
          <CookieContainer
            key={index}
            title={cookie.title}
            description={parse(cookie.description)}
            color={cookie.color}
            onClick={() => handleClick(cookie)} // Fonksiyonu buraya yönlendir
          />
        ))}
      </div>
      <Page></Page>
      <LogPanel logs={logs} /> {/* logs prop olarak gönder */}
      <EntryPanel></EntryPanel>
      {showPanelVisible && (
        <CookieDescriptionPanel
          description={showPanel}
          onClose={handleClosePanel}
        />
      )}
    </div>
  );
};

export default App;
