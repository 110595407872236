import React, { useState } from "react";
import "../Navbar.css"; // Stil dosyasını unutmayalım

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <a>How-Are-You-Being-Tracked.com</a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {/* Hamburger icon */}
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <a className="nav-links">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-links">Products</a>
          </li>
          <li className="nav-item">
            <a className="nav-links">Services</a>
          </li>
          <li className="nav-item">
            <a className="nav-links">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
