const CookieContainer = (props) => {
  const { title, color, onClick } = props;

  const containerStyle = {
    backgroundColor: color,
  };
  return (
    <div className="cookieContainer" style={containerStyle} onClick={onClick}>
      <p className="cookieTitle">{title}</p>
    </div>
  );
};

export default CookieContainer;
