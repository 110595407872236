import React, { useRef, useEffect } from "react";
import "../LogPanel.css";
import parse from "html-react-parser";

const LogPanel = ({ logs }) => {
  const logRef = useRef(null); // Ref oluştur

  useEffect(() => {
    if (logRef.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight; // En alta kaydır
    }
  }, [logs]); // logs değiştiğinde çalışacak

  return (
    <div className="logPanel">
      <h3 className="logTitle">Log Records</h3>
      <div className="logContent">
        <div className="logEntryArea" ref={logRef}>
          {" "}
          {/* Ref'i buraya ekle */}
          {logs.map((log, index) => (
            <p key={index}>{parse(log)}</p> // Her log için bir <p> elementi oluştur
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogPanel;
