import React, { useState, useEffect } from "react";
import "./App.css";
import MainContent from "./MainContent"; // Ana içeriğin bulunduğu component
import Cookies from "js-cookie";
import CookieBanner from "./CookieConsent";

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  Object.keys(Cookies.get()).forEach((cookie) => {
    Cookies.remove(cookie);
  });
  useEffect(() => {
    // Ekran genişliği kontrolünü yap
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize(); // İlk yüklemede kontrol et
    window.addEventListener("resize", handleResize); // Ekran yeniden boyutlandırıldığında kontrol et

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="screen-warning">
          <div className="warning-text">
            This website is designed for desktop screens only.
          </div>
        </div>
      ) : (
        <MainContent /> // Eğer ekran genişse, ana içeriği göster
      )}
      <CookieBanner></CookieBanner>
    </>
  );
};

export default App;
