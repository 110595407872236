import React from "react";
import "../App.css"; // Stil dosyasını ayrıca tanımlayabilirsin.

const Products = () => {
  const products = [
    {
      id: 1,
      name: "Laptop",
      price: "$1000",
      image:
        "https://cdn.vatanbilgisayar.com/Upload/PRODUCT/acer/thumb/141368-1_large.jpg",
    },
    {
      id: 2,
      name: "Refrigerator",
      price: "$400",
      image:
        "https://percdn.com/f/522538/b2NDVUptVXA3TFZ3ZUdFOVU1NG5hQT09/p/siemens-kg86naid2n-xxl-kolay-temizlenebilir-inox-nofrost-buzdolabi-29393-1.jpg",
    },
    {
      id: 3,
      name: "Headphone",
      price: "$100",
      image:
        "https://st-troy.mncdn.com/mnresize/1500/1500/Content/media/ProductImg/original/6925281964749-jbl-tune-520bt-multi-connect-wi-fi-kulaklik-mavi-638199602434115587.jpeg",
    },

    {
      id: 4,
      name: "Jersey",
      price: "$100",
      image:
        "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,e_sharpen/global/775360/01/fnd/TUR/w/1000/h/1000/fmt/png",
    },
    {
      id: 5,
      name: "Television",
      price: "$500",
      image:
        "https://statics.vestel.com.tr/productimages/20277114_r1_1000_1000.jpg",
    },
    {
      id: 6,
      name: "Smart Watch",
      price: "$100",
      image:
        "https://statics.vestel.com.tr/productimages/20292602_r1_1000_1000.jpg",
    },
    {
      id: 7,
      name: "Cupboard ",
      price: "$30",
      image:
        "https://loolpihome.com/cdn/shop/products/loolpi-home-3-kapakli-zincir-dolap-gardrop-mdf-dolap-loolpi-home-3-kapakli-dolap-gardrop-indirimi-kacirma-taksitli-odeme-32936178155699.webp?v=1679949913",
    },
    {
      id: 8,
      name: "Sofa",
      price: "$130",
      image:
        "https://img.vivense.com/1920x1280/images/45ee0f0fd5804c838121bccd5884624a.jpg",
    },
    {
      id: 9,
      name: "Car",
      price: "$20000",
      image:
        "https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg",
    },
    {
      id: 10,
      name: "Air Conditioner",
      price: "$300",
      image:
        "https://www.beko.com.tr/media/resize/8896371200_LO2_20210313_000412.png/2000Wx2000H/image.webp",
    },
    {
      id: 11,
      name: "Pot",
      price: "$70",
      image: "https://m.media-amazon.com/images/I/71+RzrqvAKL.jpg",
    },
    {
      id: 12,
      name: "Beam Sword",
      price: "$100000",
      image:
        "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/84435480-f200-44a6-b3c4-9ac7bc218377/d2nriy5-0d261232-711d-4b59-8f82-605a5c2b9ea0.jpg/v1/fill/w_900,h_720,q_75,strp/beam_sword_by_cmhart_d2nriy5-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvODQ0MzU0ODAtZjIwMC00NGE2LWIzYzQtOWFjN2JjMjE4Mzc3XC9kMm5yaXk1LTBkMjYxMjMyLTcxMWQtNGI1OS04ZjgyLTYwNWE1YzJiOWVhMC5qcGciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.gmWDwz8XJNTYmv4sfnOe5Y66FjsWCS4TS6YjfeMgrPo",
    },
  ];

  return (
    <div className="product-list">
      {products.map((product) => (
        <div key={product.id} className="product-item">
          <img
            src={product.image}
            alt={product.name}
            className="product-image"
          />
          <h3 className={`product-name ${product.name}`}>{product.name}</h3>
          <p className="product-price">{product.price}</p>
          <button className="add-to-cart">Add to Cart</button>
        </div>
      ))}
    </div>
  );
};

export default Products;
