function Advers() {
  return (
    <div className="reklam">
      <div className="ads-panel">
        <p>SUPPORTERS</p>
      </div>
    </div>
  );
}

export default Advers;
