import Navbar from "./Navbar";
import Products from "./Products";
import Advers from "./Advers";
function Page() {
  return (
    <div className="allPage">
      <Navbar></Navbar>
      <Advers></Advers>
      <Products></Products>
    </div>
  );
}

export default Page;
