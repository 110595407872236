function detectBrowser() {
  const userAgent = navigator.userAgent;
  let browserName, fullVersion;

  // Check for specific browsers
  if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Chrome";
    fullVersion = userAgent.substring(userAgent.indexOf("Chrome") + 7);
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
    fullVersion = userAgent.substring(userAgent.indexOf("Firefox") + 8);
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Safari";
    fullVersion = userAgent.substring(userAgent.indexOf("Safari") + 7);
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident") > -1
  ) {
    browserName = "Internet Explorer";
    if (userAgent.indexOf("MSIE") > -1) {
      fullVersion = userAgent.substring(userAgent.indexOf("MSIE") + 5);
    } else {
      fullVersion = userAgent.substring(userAgent.indexOf("rv:") + 3);
    }
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Edge";
    fullVersion = userAgent.substring(userAgent.indexOf("Edge") + 5);
  } else {
    browserName = "Unknown";
    fullVersion = "Unknown";
  }

  // Return an object with the browser name and version
  return {
    name: browserName,
    version: fullVersion.split(" ")[0], // Get the version before any space
  };
}

export default detectBrowser;
